import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'request-visit-doctor-dialog',
  templateUrl: './request-visit-doctor.html',
  styleUrls: ['./request-visit-doctor.scss']
})
export class RequestVisitDoctorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RequestVisitDoctorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data)
  }
  cancel(){
    //@ts-ignore
    global.presence_obj.requestVisitResponse({dest_socket:this.data.socket_source, status:'decline', reason:"busy"});
    this.dialogRef.close(true);
  }
  sendOk(){
    this.dialogRef.close(true);
    //@ts-ignore
    global.presence_obj.requestVisitResponse({dest_socket:this.data.socket_source, status:'accepted', reason:""});
  }
}
